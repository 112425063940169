document.addEventListener("DOMContentLoaded", function () {
  // 全ページ共通の機能
  setupHamburgerMenu();
  limitNavMenuTitleLength();
  toggleAbsoluteParallax()

  const path = window.location.pathname;

  // トップページ専用の機能
  if (path === "/" || path.match(/^\/([a-z]{2}\/)?$/)) {
    smoothScroll();
    trimPostTitle();
    window.addEventListener("resize", trimPostTitle);
    window.addEventListener("load", trimPostTitle);
    setupFaqAccordion();
    changeSubmitButtonTextIfEnglish();
  } else {
    // よくある質問一覧専用の機能
    if (path.match(/^\/([a-z]{2}\/)?faq\/?$/)) {
      setupFaqAccordion();
    // お知らせ一覧専用の機能
    } else if (path.match(/^\/([a-z]{2}\/)?news\/?$/)) {
      trimPostTitle();
      window.addEventListener("resize", trimPostTitle);
      window.addEventListener("load", trimPostTitle);
    }
  }
});
// ハンバーガーボタン・ドロワーメニュー開閉制御
function setupHamburgerMenu() {
  // ハンバーガーボタンとドロワーナビゲーションの選択
  const hamburgers = document.querySelectorAll(".js-hamburger");
  const drawers = document.querySelectorAll(".js-drawer");

  // 各ハンバーガーボタンにイベントリスナーを追加
  hamburgers.forEach((hamburger, index) => {
    hamburger.addEventListener("click", () => {
      const expanded = hamburger.getAttribute("aria-expanded") === "true";
      hamburger.classList.toggle("active");
      hamburger.setAttribute("aria-expanded", !expanded);

      // 対応するドロワーを取得して制御
      const drawer = drawers[index];
      if (drawer) {
        drawer.classList.toggle("active");
        drawer.setAttribute("aria-hidden", expanded);

        // ドロワーがアクティブなときにスクロールを防止
        document.body.style.overflow = drawer.classList.contains("active")
          ? "hidden"
          : "auto";
      }
    });
  });
  // ドロワーリンクのクリックイベント処理
  document.querySelectorAll(".js-drawer__link").forEach((link) => {
    link.addEventListener("click", () => {
      const drawer = link.closest(".js-drawer");
      const hamburger = hamburgers[Array.from(drawers).indexOf(drawer)];

      if (drawer) {
        drawer.classList.remove("active");
        hamburger.classList.remove("active");
        hamburger.setAttribute("aria-expanded", "false");
        drawer.setAttribute("aria-hidden", "true");

        // ドロワーが閉じたらスクロールを再び有効化
        document.body.style.overflow = "auto";
      }
    });
  });
}
// ページ内リンクのスムーズスクロール
function smoothScroll() {
  const links = document.querySelectorAll('a[href*="#"]');
  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      const targetId = link.getAttribute("href").split("#")[1];
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        event.preventDefault();
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    });
  });
}
// ドロワーメニュー・フッターメニューの文字数制限
function limitNavMenuTitleLength() {
  const getCurrentLanguage = () => {
    // 言語判定のロジックをここに追加（例：HTMLのlang属性を参照）
    return document.documentElement.lang; // 例：HTMLのlang属性を使用して言語を判定
  };

  const maxLengthSmallScreen = getCurrentLanguage() === 'ja' ? 18 : 38; // 767px以下の場合の文字数制限
  const maxLengthMediumScreen = getCurrentLanguage() === 'ja' ? 8 : 11; // 768pxから1512pxの場合の文字数制限
  const maxLengthLargeScreen = getCurrentLanguage() === 'ja' ? 20 : 20; // 1513px以上の場合の文字数制限

  const truncateText = (selector, length) => {
    document.querySelectorAll(selector).forEach(item => {
      if (item.textContent.length > length) {
        item.textContent = item.textContent.slice(0, length) + '...';
      }
    });
  };

  const applyTruncate = () => {
    let currentMaxLength;
    if (window.innerWidth <= 767) {
      currentMaxLength = maxLengthSmallScreen;
    } else if (window.innerWidth >= 1513) {
      currentMaxLength = maxLengthLargeScreen;
    } else {
      currentMaxLength = maxLengthMediumScreen;
    }
    truncateText('.js-drawer__link', currentMaxLength);
    truncateText('.js-footer__link', currentMaxLength);
  };

  applyTruncate(); // 初期実行
  window.addEventListener('resize', applyTruncate); // リサイズ時に再実行
}
// お知らせタイトルの文字数制限
function trimPostTitle() {
  var newsDetails = document.querySelectorAll(".c-news__detail");
  var lang = document.documentElement.lang; // HTMLのlang属性を取得
  newsDetails.forEach(function (newsDetail) {
    var text = newsDetail.getAttribute('data-original-text') || newsDetail.textContent;
    if (window.innerWidth <= 430) {
      if (lang === 'en-US') {
        if (text.length > 25) {
          newsDetail.textContent = text.substring(0, 25) + "…";
        } else {
          newsDetail.textContent = text;
        }
      } else {
        if (text.length > 14) {
          newsDetail.textContent = text.substring(0, 14) + "…";
        } else {
          newsDetail.textContent = text;
        }
      }
    } else {
      newsDetail.textContent = text; // 画面幅が広い場合は元のテキストを表示
    }
    newsDetail.setAttribute('data-original-text', text); // 元のテキストを属性に保存
  });
}
// アコーディオン開閉
function setupFaqAccordion() {

  const initializeAccordion = (details) => {

    const summary = details.querySelector("summary");
    const panel = details.querySelector("summary + *");
    const crossIcon = summary.querySelector(".js-faq-cross"); // クロスアイコンを取得

    if (!(details && summary && panel && crossIcon)) {
      return; // 必要要素が揃っていない場合は処理をやめる
    }
    let isTransitioning = false; // 連打防止フラグ

    const onOpen = () => {
      if (details.open || isTransitioning) return;
      isTransitioning = true;
      panel.style.gridTemplateRows = "0fr";
      details.setAttribute("open", "");
      crossIcon.style.transform = "rotate(45deg)"; // アイコンを45度回転
      requestAnimationFrame(() => {
        panel.style.gridTemplateRows = "1fr";
      });
      panel.addEventListener(
        "transitionend",
        () => {
          isTransitioning = false;
        },
        { once: true }
      );
    };

    const onClose = () => {
      if (!details.open || isTransitioning) return;
      isTransitioning = true;
      panel.style.gridTemplateRows = "0fr";
      crossIcon.style.transform = ""; // アイコンの回転をリセット
      panel.addEventListener(
        "transitionend",
        () => {
          details.removeAttribute("open");
          panel.style.gridTemplateRows = "";
          isTransitioning = false;
        },
        { once: true }
      );
    };

    summary.addEventListener("click", (event) => {
      event.preventDefault();
      if (details.open) {
        onClose();
      } else {
        onOpen();
      }
    });
  };

  const accordions = document.querySelectorAll(".js-faq-accordion");
  accordions.forEach((accordion) => {
    initializeAccordion(accordion);
  });
}
// お問い合わせフォームの"送信する"テキストは日本語以外（英語版など）Js側で翻訳されたテキストに書き換え
function changeSubmitButtonTextIfEnglish() {
  const currentLanguage = document.documentElement.lang;
  if (currentLanguage === 'en-US') {
      const submitButton = document.querySelector('input.wpcf7-form-control.wpcf7-submit');
      if (submitButton) {
          submitButton.value = 'Sending';
      }
  }
}
// フッターのパララックス画像のfixed切り替え
function toggleAbsoluteParallax() {
  const parallaxImg = document.querySelectorAll(".p-footer__bg");
  parallaxImg.forEach((element) => {
    ScrollTrigger.create({
      trigger: element,
      start: "top bottom", // 要素がビューポートに入る位置
      end: "bottom top", // 要素がビューポートから出る位置
      onEnter: () => {
        element.classList.add("fixed");
      },
      onLeave: () => {
        element.classList.remove("fixed");
      },
      onEnterBack: () => {
        element.classList.add("fixed");
      },
      onLeaveBack: () => {
        element.classList.remove("fixed");
      },
    });
  });
}